
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      title: "",
      template_content: "",
    });

    const message_count = ref("");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const page = ref<string>("");
    // const value = ref('');
    const registration = Yup.object().shape({
      title: Yup.string().required().label("Template Name"),
      template_content: Yup.string().required().label("Template Content"),
    });

    onMounted(() => {
      const data = route.params.id;
      if (data) {
        getTemplate(data);
        // page.value = "Edit";
        setCurrentPageBreadcrumbs("Edit Template Message", []);
      } else {
        // page.value = "Add";
        setCurrentPageBreadcrumbs("Add Template Message", []);
      }
      // setCurrentPageBreadcrumbs("Add User", ["User"]);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        // getUser(id);

        page.value = "Edit";
      } else {
        page.value = "Add";
      }
    });
    const getTemplate = (id) => {
      var request = {
        url: `template/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          //   console.log(data);
          formData.value = {
            title: data.data.title,
            template_content: data.data.template_content,
          };
        }
      });
    };

    const messageBox = (e) => {
      //  console.log('sdhhgds',);
      message_count.value = e.target.value.length;
    };

    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors
      // eslint-disable-next-line
         submitButton.value!.disabled = true;
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      if (values.title.trim() == "" || values.template_content.trim() == "") {
        Swal.fire({
          text: "Please fill all fields!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var id = route.params.id;
        var request = {
          url: `template/${id}`,
          data: {
            title: values.title,
            template_content: values.template_content,
          },
        };
        store
          .dispatch(Actions.PUT, request)
          .then((data) => {
            if (data) {
              Swal.fire(
                "Success",
                "Template Message updated successfully!",
                "success"
              );
              router.push({ name: "user-template-message" });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      //   }, 2000);
      // Swal.fire({
      //   text: "User added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-user" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      formData,
      page,
      getTemplate,
      message_count,
      messageBox,
    };
  },
});
